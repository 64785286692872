/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import api from 'services/api';

import { IUserProps, IProfileProps, FilialList } from 'utils/interfaces';
import { transformToSelect } from 'utils/toSelect';
import { iCreateUserParamsAPI, iUpdateUserParamsAPI } from '../types';

export const searchBoxRequest = async (
  data: any | undefined,
  clientId: number
) => {
  const requestData = {
    CodigoCliente: clientId,
    ativo: data?.ativo,
    nome: data?.nome || null,
    codigoPerfil: data?.codigoPerfil || null,
  };

  return api.patch<IUserProps[]>('/Usuario', { ...requestData });
};

export const getProfilesRequest = async (clientId: number) => {
  const requestData = {
    codigoCliente: clientId,
  };

  const profiles = await api.patch<IProfileProps[]>('/Perfil', {
    ...requestData,
  });

  const formatedData = transformToSelect(profiles.data);

  return formatedData;
};

export const getFilialsRequest = async (clientId: number) =>
  api.get(`/Filial?codigoCliente=${clientId}`);

export const createUserRequest = async (
  userData: iCreateUserParamsAPI,
  systems: FilialList[]
) => {
  const requestData = {
    filiais: systems.map(item => ({
      codigoFilial: item.codigoFilial,
    })),
    ...userData,
  };

  return api.post('/Usuario', { ...requestData });
};

export const editUserRequest = async (
  formData: iUpdateUserParamsAPI,
  systems: FilialList[]
) => {
  const requestData = {
    filiais: systems.map(item => ({
      codigoFilial: item.codigoFilial,
    })),
    ...formData,
  };

  return api.put('/Usuario', { ...requestData });
};

export const deleteUserRequest = async (userCode: number) =>
  api.delete(`/Usuario?codigo=${userCode}`);

export const getAllModalOptions = async (clientId: number) => {
  const requestData = {
    codigoCliente: clientId,
  };

  const [filiaisReq, profilesReq] = await Promise.all([
    api.get(`/Filial?codigoCliente=${clientId}`),
    api.patch('/Perfil', {
      ...requestData,
    }),
    // api.get(`/Supplier?codigoCliente=${clientId}`),
  ]);

  const formatedProfiles = transformToSelect(profilesReq.data);

  // const responseApi: ISupplyData[] = supplier.data;

  // const transformSupplier: SelectItem[] = responseApi.map((s: ISupplyData) => {
  //   return {
  //     value: s.codigoFornecedor,
  //     label: s.nomeFornecedor,
  //   };
  // });

  return [filiaisReq.data, formatedProfiles];
};

export const getSupplierAPI = async (clientId: number) =>
  api.get(`/Supplier?codigoCliente=${clientId}`);

export const resetPasswordRequest = (userData: IUserProps) => {
  const requestData = {
    codigoUsuario: userData.codigoUsuario,
    email: userData.email,
    login: userData.login,
    nome: userData.nome,
  };

  return api.put('Usuario/ReiniciarSenha', { ...requestData });
};
