import React, { createContext, useCallback, useContext, useState } from 'react';
import api from '../../services/api';

interface SignInCredentials {
  login: string;
  password: string;
  tokenResponse: string | null;
}

interface AuthState {
  token: string;
  user: User | null;
}

interface User {
  data: {
    accessToken: string;
    codigoUsuario: number;
    login: string;
    nome: string;
    email: string;
    ativo: boolean;
    alterarSenha: boolean;
    codigoPerfil: number;
    descricaoPerfil: string;
    lider: boolean;
  };
}

interface Token {
  data: {
    accessToken: string;
    codigoUsuario: number;
    login: string;
    nome: string;
    email: string;
    ativo: boolean;
    alterarSenha: boolean;
    codigoPerfil: number;
    descricaoPerfil: string;
    lider: boolean;
  };
}

interface AuthContextData {
  user: User | null;
  token: string;
  signIn(credentials: SignInCredentials): Promise<User>;
  signOut(): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState(() => {
    const token = localStorage.getItem('@pdamodules::accessToken');
    const user = localStorage.getItem('@pdamodules::user');

    if (token && user) {
      return { token, user: JSON.parse(user) };
    }

    return {
      token: 'void',
      user: null,
    } as AuthState;
  });

  const signIn = useCallback(
    async ({ login, password, tokenResponse }: SignInCredentials) => {
      try {
        const responseLogin = await api.post('Autenticacao', {
          login,
          password,
          tokenResponse,
        });

        const response = {
          active: responseLogin.data.authenticated,
          token: responseLogin.data.accessToken,
          expiration: responseLogin.data.expiration,
          refresh: responseLogin.data.refreshToken,
          created: responseLogin.data.created,
        };

        localStorage.setItem(
          '@pdamodules::exception',
          JSON.stringify(response.expiration)
        );

        if (response.active) {
          const responseToken: Token = await api.get(
            `Autenticacao?refreshToken=${response.refresh}`,
            {
              headers: {
                Authorization: `Bearer ${response.token}`,
              },
            }
          );

          if (responseToken.data.accessToken) {
            const userToStore: User = {
              data: {
                codigoUsuario: Number(responseToken.data.codigoUsuario),
                nome: responseToken.data.nome,
                ativo: Boolean(responseToken.data.ativo),
                accessToken: responseLogin.data.accessToken,
                login: responseToken.data.login,
                codigoPerfil: Number(responseToken.data.codigoPerfil),
                descricaoPerfil: responseToken.data.descricaoPerfil,
                alterarSenha: responseToken.data.alterarSenha,
                email: responseToken.data.email,
                lider: responseToken.data.lider,
              },
            };

            localStorage.setItem(
              '@pdamodules::accessToken',
              JSON.stringify(userToStore.data.accessToken)
            );
            localStorage.setItem(
              '@pdamodules::codigoUsuario',
              JSON.stringify(userToStore.data.codigoUsuario)
            );
            localStorage.setItem(
              '@pdamodules::user',
              JSON.stringify(userToStore)
            );
            localStorage.setItem(
              '@pdamodules::authenticated',
              JSON.stringify(userToStore.data.ativo)
            );

            localStorage.setItem('@pdamodules::refresh', response.refresh);
            localStorage.setItem(
              '@pdamodules::login',
              JSON.stringify(userToStore.data.login)
            );

            localStorage.setItem(
              '@pdamodules::password',
              String(userToStore.data.alterarSenha)
            );

            setData({
              token: userToStore.data.accessToken,
              user: userToStore,
            });

            return userToStore;
          }
        }
        throw new Error(response.token);
      } catch (err) {
        throw new Error(String(err));
      }
    },
    []
  );

  const signOut = useCallback(() => {
    localStorage.removeItem('@pdamodules::cliente');
    localStorage.removeItem('@pdamodules::accessToken');
    localStorage.removeItem('@pdamodules::user');
    localStorage.removeItem('@pdamodules::id');
    localStorage.removeItem('@pdamodules::codigoUsuario');
    localStorage.removeItem('@pdamodules::codigoCliente');
    localStorage.removeItem('@pdamodules::authenticated');
    localStorage.removeItem('@pdamodules::login');
    localStorage.removeItem('@pdamodules::exception');
    localStorage.removeItem('@pdamodules::refresh');
    localStorage.removeItem('@pdamodules::password');
    localStorage.removeItem('@pdamodules::hash');
    localStorage.removeItem('@pdamodules::created');
    localStorage.removeItem('@pdamodules::time');
    localStorage.removeItem('@pdamodules::active');
    localStorage.removeItem('@pdamodules::token');

    setData({} as AuthState);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        token: data.token,
        user: data.user,
        signIn,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within a AuthProvider');
  }

  return context;
}

export { AuthContext, AuthProvider, useAuth };
