import * as Yup from 'yup';

import { IUserProps } from 'utils/interfaces';

type handleGetUsers = (data?: object) => Promise<void>;

export interface ICreateUserProps {
  isOpen: boolean;
  handleClose(): void;
  getUsers: handleGetUsers;
}

export interface IUsersTableProps {
  rows: IUserProps[];
  getUsers: handleGetUsers;
}

export interface IEditProfileModalProps {
  isOpen: boolean;
  handleClose(): void;
  userData: IUserProps;
  clearCurrentUser(): void;
  getUsers: handleGetUsers;
}

export interface IDeleteProfileModalProps {
  isOpen: boolean;
  handleClose(): void;
  userData: IUserProps;
  clearCurrentUser(): void;
  getUsers: handleGetUsers;
}

export interface ISupplyData {
  nomeFornecedor: string;
  codigoFornecedor: number;
  codigoFornecedorERP: string;
}

export const formCreateUserSchema = Yup.object({
  login: Yup.string().required(),
  nome: Yup.string().required(),
  email: Yup.string().email().required(),
  ativo: Yup.boolean().required(),
});

export interface iCreateAndUpdateUserParams {
  login: string;
  nome: string;
  email: string;
  ativo: boolean;
  codigoPerfil?: number | null;
  codigoFornecedor?: number | null;
}

export interface iCreateUserParamsAPI extends iCreateAndUpdateUserParams {
  codigoCliente: number;
}

export interface iUpdateUserParamsAPI extends iCreateUserParamsAPI {
  codigoUsuario: number;
}
