export default {
  translations: {
    screens: {
      header: {
        hello: 'Hello',
        support: 'Support',
        help: 'Help',
        about: 'About',
      },
      profile: {
        title: 'Profile',
        description: 'Description',
        isLeader: 'Are you a leader?',
        titleEdit: 'Systems',
        switchButtonEdit: 'Leader Only',
        editSucces: 'Profile edited successfully!',
        editProfile: 'Edit profile:',
        createProfile: 'Create profile',
        newProfile: 'New Profile',
        create: 'To Create',
        systemsNullablw: 'Select any system to create a profile',
        createProfileSucces: 'Profile successfully created!',
        save: 'Save',
        menuDescription: 'Menu Description',
        integrate: 'Integrate',
        saving: 'Salving',
        permissionsSaving: 'Saved permissions',
        msgNoProfile: 'No profile records',
      },
      profileTable: {
        code: 'Code',
        isLeader: 'Leader',
        actions: 'Actions',
        edit: 'To edit',
        permissions: 'Permissions',
      },
      user: {
        userTitle: 'Users',
        profile: 'Profile',
        name: 'Name',
        isActive: 'Is It Active',
        newUser: 'New User',
        msgNoUser: 'No user records',
        resultFound: 'result(s) found!',
        resetPassword: 'Reset Password',
        branchs: 'Branchs',
        editPassword: 'Password successfully reset',
        error: 'Error',
        errorLoginInUse:
          'This login is already in use. Please, choose another login',
        errorAdminLogin: 'Admin User cannot be used',
        editUser: 'Edit User',
        createUser: 'Create User',
        createUserSucces: 'Usuário criado com sucesso!',
        IsNullBranch: 'Select any branch to create a user!',
        selected: 'Selected',
        selectedSystem: 'Select a system!',
        noRecordes: 'There are no records',
        errorCreateAndUpdateMsg:
          'Fill in all required fields. Make sure the email is in the correct format, for example: john.doe@email.com.',
      },
      userTable: {
        code: 'Code',
        login: 'Login',
        status: 'Status',
        delete: 'Delete',
      },
      userDelete: {
        inactivatedSucces: 'User inactivated successfully!',
        userInativated: 'Inactivated User!',
        wantToLeave: 'Want to leave',
        inactive: 'inactive',
      },
    },
    components: {
      table: {
        advancedFilter: 'Advanced Filters',
        lessFilter: 'Less filters',
        loagind: 'Loading',
        search: 'Search',
        clean: 'To clean',
        toAdd: 'To add',
        fileUpload: 'File Upload',
        cancel: 'Cancel',
        notFound: 'No record found',
        export: 'Export',
        recordes: 'Records',
        to: 'to',
        toClose: 'To close',
        confirm: 'Confirm',
      },
    },
  },
};
